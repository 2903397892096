<template>
    <article>
        <div class="eventContainer">

            <div class="mainView">
                <el-tabs v-model="activeName" style="font-size: 25px;">
                    <el-tab-pane :label="'Ongoing (' + ongoingArr.length + ')'" name="second">

                        <el-table :data="ongoingArr" empty-text="No Data" header-cell-class-name="adorn" size="medium"
                            border ref="leftTable" class="left-table">
                            <el-table-column label="Event Name" align="left">
                                <template slot-scope="scope">
                                    {{ scope.row.project_name }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                :label="'Event Time (' + account_continent + ': UTC+' + timezones[account_continent] + ')'"
                                align="left">

                                <template slot-scope="scope">
                                    <div style="padding-left: 0px;white-space:pre-line;text-align: left;">

                                        <template v-for="(item, index) in scope.row.exam_time_arr">
                                            <div>
                                                {{ item.name }} ({{ item.exam_type }}):
                                                <ul>
                                                    <li>&#8226; Start Time: {{ item.exam_begin_time_str }}</li>
                                                    <li>&#8226; End Time: {{ item.exam_end_time_str }}</li>
                                                </ul>

                                            </div>
                                        </template>
                                    </div>

                                </template>
                            </el-table-column>


                            <el-table-column width="200" label="Operations" align="left">
                                <template slot-scope="scope">
                                    <div style="float: left;">

                                        <el-button v-if="scope.row.can_go_lecture == true" class="btn-link"
                                            @click="toPackage(scope.row)" type="primary" size="small">Enter
                                            Lecture</el-button>
                                        <el-button v-if="scope.row.can_go_exam == true" class="btn-link"
                                            @click="toStudentEventList(scope.row)" type="primary" size="small">Enter
                                            Exam</el-button>
                                        <!-- <el-button class="btn-link" @click="toPackage(scope.row)" type="primary" size="small">Choose Time</el-button> -->
                                    </div>



                                </template>
                            </el-table-column>
                        </el-table>


                    </el-tab-pane>
                    <el-tab-pane :label="'Pending (' + pendingArr.length + ')'" name="first">

                        <el-table :data="pendingArr" empty-text="No Data" header-cell-class-name="adorn" size="medium"
                            border ref="leftTable" class="left-table">
                            <el-table-column label="Event Name" align="left">
                                <template slot-scope="scope">
                                    {{ scope.row.project_name }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Deadline" width="200" align="left">

                                <template slot-scope="scope">
                                    {{ scope.row.deadline_date_str }}
                                </template>
                            </el-table-column>

                            <el-table-column label="Status" width="200" align="left">
                                <template slot-scope="scope">
                                    {{ scope.row.application_status_show }}
                                </template>
                            </el-table-column>

                            <el-table-column width="350" label="Operations" align="left">
                                <template slot-scope="scope">
                                    <div style="float: left;">

                                        <el-button v-if="scope.row.application_status_show == 'Pending Payment'"
                                            class="btn-link" @click="gotoSelectPassView(scope.row._id.$id)"
                                            type="primary" size="small">Verify Pass</el-button>
                                        <el-button v-if="scope.row.application_status_show == 'Pending Payment'"
                                            class="btn-link" @click="toPayView(scope.row._id.$id)" type="primary"
                                            size="small">Make Payment</el-button>
                                        <el-button v-if="scope.row.application_status_show == 'Pending Payment'"
                                            class="btn-link" @click="toCancel(scope.row)" type="primary"
                                            size="small">Cancel</el-button>

                                        <el-button v-if="scope.row.student_admission_status == '待录取'" class="btn-link"
                                            @click="toPreferences(scope.row)" type="primary" size="small">Event
                                            Preferences</el-button>

                                    </div>


                                    <!-- |  |  -->

                                </template>
                            </el-table-column>
                        </el-table>


                    </el-tab-pane>



                    <el-tab-pane :label="'Completed (' + completedArr.length + ')'" name="third">

                        <el-table :data="completedArr" empty-text="No Data" header-cell-class-name="adorn" size="medium"
                            border ref="leftTable" class="left-table">
                            <el-table-column label="Event Name" align="left">
                                <template slot-scope="scope">
                                    {{ scope.row.project.project_product }}
                                </template>
                            </el-table-column>


                            <el-table-column label="Score" align="left">

                                <template slot-scope="scope">
                                    {{ scope.row.score ? scope.row.score.total_score : '' }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Award" align="left">
                                <template slot-scope="scope">
                                    <div>{{ scope.row.level_and_grade ? formatGradeInfo(scope.row.level_and_grade.grade) : '' }}
                                    </div>
                                    <div v-if="scope.row.awards && scope.row.awards.length > 0">
                                        <div v-for="(award, index) in scope.row.awards" :key="index">
                                            {{ award.award_lable }}
                                        </div>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column width="360" label="Operations" align="left">
                                <template slot-scope="scope">
                                    <div style="float: left;">

                                        <el-button class="btn-link"  @click="gotoAwardView(scope.row)" type="primary"
                                            size="small">Certificate</el-button>
                                        <!-- <el-button class="btn-link" @click="gotoAwardView(scope.row)" type="primary"
                                            size="small">Transcript</el-button> -->
                                        <!-- <el-button class="btn-link" @click="toPackage(scope.row)" type="primary" size="small">Register Next Round</el-button> -->
                                    </div>



                                </template>
                            </el-table-column>
                        </el-table>



                    </el-tab-pane>
                </el-tabs>
            </div>

        </div>


    </article>
</template>
<script>
import {
    getStudentApplications,
    cancelApplication,


} from "../../api/index";

import Cookie from "js-cookie";
import { getUserId, getUserType, getAccountContinent } from "../../utils/store";

export default {
    data() {
        return {
            loginType: getUserType(),
            loginId: getUserId(),
            activeName: 'second',
            defevent: 0,

            pendingArr: [],
            ongoingArr: [],
            completedArr: [],
            account_continent: getAccountContinent(),
            timezones: {
                'Asia': 8,
                'Oceania': 8,
                'Europe': 1,
                'Africa': 1,
                'North America': -4,
                'South America': -4

            },

        };
    },
    created() {


    },
    mounted() {
        this.loading = true;
        this.$nextTick(() => {
            this.$loading({
                lock: true,
                text: 'Loading...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        });
        this.getAppByEvent()

    },
    methods: {

        formatGradeInfo(grade) {
            if (grade < 0 ) {
                return ''
            }
            if (grade >= 4) {
                return 'Excellence'
            }
            if (grade >= 3) {
                return 'Distinction'
            }
            if (grade >= 2) {
                return 'Great Distinction'
            }
            return 'Highest Distinction'
        },

        gotoAwardView(row) {
            // if(false){
            //     return
            // }
            this.$router.push({
                path: '/award',
                query: {
                    project_code: row.application_project,
                }
            })
        },


        toStudentEventList(row) {

            Cookie.set('exam_event_name', row.project_name)

            this.$router.push({
                path: '/studentlist',
                query: {
                    project_code: row.application_project
                }
            })
        },

        gotoSelectPassView(application_id) {
            this.$router.push({
                path: '/home/verify-pass',
                query: {
                    application_id: application_id
                }
            })
        },

        toPayView(application_id) {
            this.$router.push({
                path: '/home/program-order',
                query: {
                    application_id: application_id
                }
            })
        },

        toPreferences(row) {

        },



        getAppByEvent() {



            getStudentApplications(this.loginId).then((res) => {


                // console.log(1111111,res.data)

                if (res.data.code == 0) {

                    this.pendingArr = res.data.data.pending_apps
                    this.ongoingArr = res.data.data.ongoing_apps
                    this.completedArr = res.data.data.completed_apps

                } else {
                    this.$message.error(res.msg);
                }
            }).finally(() => {
                this.$loading().close();
            });



        },
        gotoPay(item) {
            this.$router.push({
                path: '/pay',
                query: {
                    id: item._id.$id
                }
            })
        },

        toCancel(row) {

            this.$confirm('Confirm cancellation?', 'Tip', {
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(res => {
                cancelApplication(row._id.$id).then((res) => {

                    this.$message.success('Cancel Successfully');
                    this.getAppByEvent()
                });

            })
        },



        manageProject(item) {

            if (item.application_status !== '已缴费' && item.application_status !== '无需缴费') {
                this.$message.error("You haven't paid yet")
                return
            }


            Cookie.set("projectTeamRole", item.application_team_role);
            Cookie.set("projectTeamEvent", item.application_events[0]);

            // mr 模版id  631169b9a4640b60318b4602
            // poe 模版id  63116846a4640b19318b4567
            // pie 模版id  63116951a4640b60318b4567

            // let pathTemplate_id = '631169b9a4640b60318b4602'

            // if (this.application.program==='PIE'){
            //   pathTemplate_id = '63116846a4640b19318b4567'

            // }else if (this.application.program==='POE'){
            //   pathTemplate_id = '63116951a4640b60318b4567'

            // }

            // if (this.from == 'student') {
            if (item.coordinator_id && item.coordinator_id != '') {
                const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, project_id: item.project_id, application_team_code: item.application_team_code, accountid: item.coordinator_id } })
                window.open(routeData.href, '_blank')
            } else {
                const routeData = this.$router.resolve({ path: 'pghome', query: { template_id: item.template_id, project_id: item.project_id, application_team_code: item.application_team_code, accountid: item.account_id } })
                window.open(routeData.href, '_blank')

            }

        }
    },
};
</script>
<style lang="scss" scoped>
article {

    background-color: rebeccapurple;
    // margin-left: 100px;
    // margin-top: 50px;
    background: #fff;
    // padding: 0 10px;
    background: yellow;

    .el-tabs_item.is-active {
        font-size: 14px;
        font-weight: 500;
        color: #204386;
    }

    .el-tabs_active-bar {
        background-color: #ffb414;
    }

    .el-tabs_item {
        font-size: 20px;
        font-weight: 500;
        color: aquamarine;
    }


    .eventContainer {
        height: calc(100vh - 80px);
        width: 100vw;
        display: flex;
        overflow: hidden;
        background: #fff;
        // background: #ffb414;
        font-family: "DDINAlternateRegular";

        .mainView {
            margin-left: 5%;
            margin-right: 5%;
            flex: 1;
            // width: 100%;
            height: 100%;
            background: white;
            overflow: hidden;
            position: relative;
        }

        .projectList {
            margin-top: 20px;
            // background-color: red;
            height: 70vh;
            width: 100%;
            overflow-y: auto;
            position: relative;

        }

        .itemStyle {
            // margin-left: 20px;
            // margin-right: 20px;
            margin-top: 15px;
            width: 100%;
            position: relative;
            height: 150px;
            background-color: oldlace;
            border-radius: 5px;
        }

        .itemStyle2 {
            // margin-left: 20px;
            // margin-right: 20px;
            margin-top: 15px;
            width: 100%;
            position: relative;
            height: 150px;
            background-color: #c3cce9;
            border-radius: 5px;
        }

    }



    .teamCodeStyle {
        padding-top: 10px;
        margin-left: 10px;
        color: #333;
        font-size: 15px;
    }

    .teamCodeStyle1 {
        background-color: #204386;
        width: 185px;
        // padding-top: 10px;
        position: relative;
        margin-top: 10px;
        margin-left: 10px;
        color: white;
        font-size: 15px;
    }


    .projectTitleStyle {
        margin-left: 10px;
        margin-top: 5px;
        color: #333;
        font-size: 25px;
    }

    .projectTitleStylePending {
        margin-left: 10px;
        margin-top: 38px;
        color: #333;
        font-size: 25px;
    }

    .leaderStyle {
        margin-left: 10px;
        margin-top: 25px;
        color: #333;
        font-size: 18px;
    }

    .memberStyle {
        margin-left: 10px;
        margin-top: 5px;
        color: #333;
        font-size: 18px;
    }

    .infoStyle {
        position: absolute;
        right: 10px;
        top: 15px;
        width: 80%;
        text-align: right;
        font-size: 15px;
    }

    .manageStyle {
        position: absolute;
        bottom: 15px;
        right: 10px;
        width: 80px;
        text-align: center;
        background-color: #ffb414;
        border: none;
        color: #333;
    }

    .manageStylePending {
        position: absolute;
        bottom: 15px;
        right: 10px;
        width: 80px;
        text-align: center;
        background-color: #204386;
        border: none;
        color: white;
    }

    .removeStyle {
        position: absolute;
        bottom: 15px;
        right: 100px;
        width: 80px;
        text-align: center;
        background-color: #ffb414;
        border: none;
        color: #333;
    }

    .removeStylePending {
        position: absolute;
        bottom: 15px;
        right: 100px;
        width: 80px;
        text-align: center;
        background-color: #204386;
        border: none;
        color: white;
    }

    .eventLinkStyle {
        margin-left: 10px;
        margin-right: 10px;
        height: 40px;
        margin-top: 10px;
        width: calc(100% - 20px);
    }

    .eventName {
        color: #333;
        padding-right: 10px;
        // width: 230px;
        height: 30px;
        text-align: right;
        line-height: 30px;
        // font-size: 18px;
        background-color: oldlace;
        // float: left;

    }

    .linkStyle {
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        line-height: 30px;
        background-color: #f5f5f5;

        overflow: hidden;
        /* 隐藏超出内容的区域 */
        white-space: nowrap;
        /* 不允许文本折行 */
        text-overflow: ellipsis;
        /* 显示省略号代替超出部分的文本 */
    }

    .shareBtn {
        line-height: 30px;
        width: 80px;
        height: 30px;
        text-align: center;
        background-color: #ffb414;
    }

}
</style>